.k-spinner:before {
	left:50%;
	margin-left:-10px;
}
.k-spinner{
	height:20px;
}
.table-responsive{
	/*overflow-: visible;*/
	overflow: hidden;
}
.modal.fade {
	opacity: 1 !important;
}
ul.suggestions {
	list-style-type: none;
	padding: 10px 0 0 0px;
}

ul.suggestions li:hover {
	color: #5d78ff;
	cursor: pointer;
	margin-left: 10px;
	margin-bottom: 5px;
}
ul.suggestions li {
	margin-left: 10px;
	margin-bottom: 5px;
}
.pdf-overlay-preview {
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0, 0.8);
	overflow-x: hidden;
	overflow-y: clip;
}
.pdf-overlay-preview .exit-fullscreen {
	text-align: center;
	background-color: #000000;
	padding: 10px 0;
}
.pdf-overlay-preview .exit-fullscreen a {
	font-size: 20px;
	color: #ffffff;
}

#document_storage-table .file-size-info {
	margin-left: 5px;
	font-size: 12px;
}

.dragging {
	opacity: 0.5;
	background-color: #99f;
}
.dragover {
	border: 1px dotted #000;
}
