@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Thin.eot") format("truetype");
    src: url("/fonts/Poppins-Thin.eot?#iefix") format('embedded-opentype'),
        url("/fonts/Poppins-Thin.woff2") format('woff2'),
        url("/fonts/Poppins-Thin.woff") format('woff'),
        url("/fonts/Poppins-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-ThinItalic.eot") format("truetype");
    src: url("/fonts/Poppins-ThinItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-ThinItalic.woff2") format('woff2'),
    url("/fonts/Poppins-ThinItalic.woff") format('woff'),
    url("/fonts/Poppins-ThinItalic.ttf") format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-ExtraLight.eot") format("truetype");
    src: url("/fonts/Poppins-ExtraLight.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-ExtraLight.woff2") format('woff2'),
    url("/fonts/Poppins-ExtraLight.woff") format('woff'),
    url("/fonts/Poppins-ExtraLight.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-ExtraLightItalic.eot") format("truetype");
    src: url("/fonts/Poppins-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-ExtraLightItalic.woff2") format('woff2'),
    url("/fonts/Poppins-ExtraLightItalic.woff") format('woff'),
    url("/fonts/Poppins-ExtraLightItalic.ttf") format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Light.eot") format("truetype");
    src: url("/fonts/Poppins-Light.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-Light.woff2") format('woff2'),
    url("/fonts/Poppins-Light.woff") format('woff'),
    url("/fonts/Poppins-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-LightItalic.eot") format("truetype");
    src: url("/fonts/Poppins-LightItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-LightItalic.woff2") format('woff2'),
    url("/fonts/Poppins-LightItalic.woff") format('woff'),
    url("/fonts/Poppins-LightItalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Regular.eot") format("truetype");
    src: url("/fonts/Poppins-Regular.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-Regular.woff2") format('woff2'),
    url("/fonts/Poppins-Regular.woff") format('woff'),
    url("/fonts/Poppins-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Italic.eot") format("truetype");
    src: url("/fonts/Poppins-Italic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-Italic.woff2") format('woff2'),
    url("/fonts/Poppins-Italic.woff") format('woff'),
    url("/fonts/Poppins-Italic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Medium.eot") format("truetype");
    src: url("/fonts/Poppins-Medium.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-Medium.woff2") format('woff2'),
    url("/fonts/Poppins-Medium.woff") format('woff'),
    url("/fonts/Poppins-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-MediumItalic.eot") format("truetype");
    src: url("/fonts/Poppins-MediumItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-MediumItalic.woff2") format('woff2'),
    url("/fonts/Poppins-MediumItalic.woff") format('woff'),
    url("/fonts/Poppins-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-SemiBold.eot") format("truetype");
    src: url("/fonts/Poppins-SemiBold.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-SemiBold.woff2") format('woff2'),
    url("/fonts/Poppins-SemiBold.woff") format('woff'),
    url("/fonts/Poppins-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-SemiBoldItalic.eot") format("truetype");
    src: url("/fonts/Poppins-SemiBoldItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-SemiBoldItalic.woff2") format('woff2'),
    url("/fonts/Poppins-SemiBoldItalic.woff") format('woff'),
    url("/fonts/Poppins-SemiBoldItalic.ttf") format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Bold.eot") format("truetype");
    src: url("/fonts/Poppins-Bold.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-Bold.woff2") format('woff2'),
    url("/fonts/Poppins-Bold.woff") format('woff'),
    url("/fonts/Poppins-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-BoldItalic.eot") format("truetype");
    src: url("/fonts/Poppins-BoldItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-BoldItalic.woff2") format('woff2'),
    url("/fonts/Poppins-BoldItalic.woff") format('woff'),
    url("/fonts/Poppins-BoldItalic.ttf") format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-ExtraBold.eot") format("truetype");
    src: url("/fonts/Poppins-ExtraBold.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-ExtraBold.woff2") format('woff2'),
    url("/fonts/Poppins-ExtraBold.woff") format('woff'),
    url("/fonts/Poppins-ExtraBold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-ExtraBoldItalic.eot") format("truetype");
    src: url("/fonts/Poppins-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-ExtraBoldItalic.woff2") format('woff2'),
    url("/fonts/Poppins-ExtraBoldItalic.woff") format('woff'),
    url("/fonts/Poppins-ExtraBoldItalic.ttf") format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Black.eot") format("truetype");
    src: url("/fonts/Poppins-Black.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-Black.woff2") format('woff2'),
    url("/fonts/Poppins-Black.woff") format('woff'),
    url("/fonts/Poppins-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-BlackItalic.eot") format("truetype");
    src: url("/fonts/Poppins-BlackItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Poppins-BlackItalic.woff2") format('woff2'),
    url("/fonts/Poppins-BlackItalic.woff") format('woff'),
    url("/fonts/Poppins-BlackItalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Thin.eot") format("truetype");
    src: url("/fonts/Roboto-Thin.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Thin.woff2") format('woff2'),
    url("/fonts/Roboto-Thin.woff") format('woff'),
    url("/fonts/Roboto-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-ThinItalic.eot") format("truetype");
    src: url("/fonts/Roboto-ThinItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-ThinItalic.woff2") format('woff2'),
    url("/fonts/Roboto-ThinItalic.woff") format('woff'),
    url("/fonts/Roboto-ThinItalic.ttf") format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Light.eot") format("truetype");
    src: url("/fonts/Roboto-Light.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Light.woff2") format('woff2'),
    url("/fonts/Roboto-Light.woff") format('woff'),
    url("/fonts/Roboto-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-LightItalic.eot") format("truetype");
    src: url("/fonts/Roboto-LightItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-LightItalic.woff2") format('woff2'),
    url("/fonts/Roboto-LightItalic.woff") format('woff'),
    url("/fonts/Roboto-LightItalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Regular.eot") format("truetype");
    src: url("/fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Regular.woff2") format('woff2'),
    url("/fonts/Roboto-Regular.woff") format('woff'),
    url("/fonts/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Italic.eot") format("truetype");
    src: url("/fonts/Roboto-Italic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Italic.woff2") format('woff2'),
    url("/fonts/Roboto-Italic.woff") format('woff'),
    url("/fonts/Roboto-Italic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Medium.eot") format("truetype");
    src: url("/fonts/Roboto-Medium.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Medium.woff2") format('woff2'),
    url("/fonts/Roboto-Medium.woff") format('woff'),
    url("/fonts/Roboto-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-MediumItalic.eot") format("truetype");
    src: url("/fonts/Roboto-MediumItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-MediumItalic.woff2") format('woff2'),
    url("/fonts/Roboto-MediumItalic.woff") format('woff'),
    url("/fonts/Roboto-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Bold.eot") format("truetype");
    src: url("/fonts/Roboto-Bold.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Bold.woff2") format('woff2'),
    url("/fonts/Roboto-Bold.woff") format('woff'),
    url("/fonts/Roboto-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-BoldItalic.eot") format("truetype");
    src: url("/fonts/Roboto-BoldItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-BoldItalic.woff2") format('woff2'),
    url("/fonts/Roboto-BoldItalic.woff") format('woff'),
    url("/fonts/Roboto-BoldItalic.ttf") format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Black.eot") format("truetype");
    src: url("/fonts/Roboto-Black.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-Black.woff2") format('woff2'),
    url("/fonts/Roboto-Black.woff") format('woff'),
    url("/fonts/Roboto-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-BlackItalic.eot") format("truetype");
    src: url("/fonts/Roboto-BlackItalic.eot?#iefix") format('embedded-opentype'),
    url("/fonts/Roboto-BlackItalic.woff2") format('woff2'),
    url("/fonts/Roboto-BlackItalic.woff") format('woff'),
    url("/fonts/Roboto-BlackItalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
}