/*
$k-media-breakpoints: (
    // Small screen / phone
    sm: 576px,
    
    // Medium screen / tablet
    md: 768px,
    
    // Large screen / desktop
    lg: 1024px,
    
    // Extra large screen / wide desktop
    xl: 1200px,

    // Extra large screen / wide desktop
    xxl: 1600px
)!default;
*/
@media (min-width: 1025px) {
  .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15); }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item > .k-menu__link .k-menu__link-icon {
      color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
      background-color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
      background-color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item > .k-menu__link .k-menu__link-text {
      color: #5f6281; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item > .k-menu__link .k-menu__hor-arrow {
      color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--active > .k-menu__link {
      background-color: #fafbff; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-icon {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--active > .k-menu__link > .k-menu__hor-arrow {
        color: #3c75fd; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--here > .k-menu__link {
      background-color: #fafbff; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-icon {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-text {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--here > .k-menu__link > .k-menu__hor-arrow {
        color: #3c75fd; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link {
      background-color: #fafbff; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-icon, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-icon {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-text, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-text {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link > .k-menu__hor-arrow, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link > .k-menu__hor-arrow {
        color: #3c75fd; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content > .k-menu__item {
      border-right: 1px solid #f6f6f9; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__heading > .k-menu__link-text {
      color: #5f6281; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__heading > .k-menu__link-icon {
      color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item > .k-menu__link .k-menu__link-icon {
      color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
      background-color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
      background-color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item > .k-menu__link .k-menu__link-text {
      color: #5f6281; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item > .k-menu__link .k-menu__hor-arrow {
      color: #cacad2; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--active > .k-menu__link {
      background-color: #fafbff; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-icon {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--active > .k-menu__link > .k-menu__hor-arrow {
        color: #3c75fd; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--here > .k-menu__link {
      background-color: #fafbff; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-icon {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-text {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--here > .k-menu__link > .k-menu__hor-arrow {
        color: #3c75fd; }
    .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link {
      background-color: #fafbff; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-icon, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-icon {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-text, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link .k-menu__link-text {
        color: #3c75fd; }
      .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item:hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link > .k-menu__hor-arrow, .k-header-menu .k-menu__nav > .k-menu__item .k-menu__submenu > .k-menu__subnav .k-menu__content .k-menu__inner > .k-menu__item.k-menu__item--hover:not(.k-menu__item--here):not(.k-menu__item--active) > .k-menu__link > .k-menu__hor-arrow {
        color: #3c75fd; } }
