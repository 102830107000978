/*
$k-media-breakpoints: (
    // Small screen / phone
    sm: 576px,
    
    // Medium screen / tablet
    md: 768px,
    
    // Large screen / desktop
    lg: 1024px,
    
    // Extra large screen / wide desktop
    xl: 1200px,

    // Extra large screen / wide desktop
    xxl: 1600px
)!default;
*/
.k-aside {
  background-color: #242939; }
  .k-aside .ps > .ps__rail-x {
    background: transparent !important; }
    .k-aside .ps > .ps__rail-x:hover, .k-aside .ps > .ps__rail-x:focus {
      opacity: 1;
      background: transparent !important; }
      .k-aside .ps > .ps__rail-x:hover > .ps__thumb-x, .k-aside .ps > .ps__rail-x:focus > .ps__thumb-x {
        opacity: 1; }
    .k-aside .ps > .ps__rail-x > .ps__thumb-x {
      background: #637099;
      opacity: 1; }
      .k-aside .ps > .ps__rail-x > .ps__thumb-x:hover, .k-aside .ps > .ps__rail-x > .ps__thumb-x:focus {
        opacity: 1;
        background: #637099; }
  .k-aside .ps > .ps__rail-y {
    background: transparent !important; }
    .k-aside .ps > .ps__rail-y:hover, .k-aside .ps > .ps__rail-y:focus {
      background: transparent !important;
      opacity: 1; }
      .k-aside .ps > .ps__rail-y:hover > .ps__thumb-y, .k-aside .ps > .ps__rail-y:focus > .ps__thumb-y {
        opacity: 1; }
    .k-aside .ps > .ps__rail-y > .ps__thumb-y {
      background: #637099;
      opacity: 1; }
      .k-aside .ps > .ps__rail-y > .ps__thumb-y:hover, .k-aside .ps > .ps__rail-y > .ps__thumb-y:focus {
        opacity: 1;
        background: #637099; }
  .k-aside .k-aside__footer {
    background-color: #1f2231; }
    .k-aside .k-aside__footer .btn {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      background-color: transparent; }
      .k-aside .k-aside__footer .btn i {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: #364b8b; }
    .k-aside .k-aside__footer .show .btn,
    .k-aside .k-aside__footer .btn:hover {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      background-color: #191b27; }
      .k-aside .k-aside__footer .show .btn i,
      .k-aside .k-aside__footer .btn:hover i {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: #3c75fd; }

.k-aside-menu {
  background-color: #242939 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__heading .k-menu__link-text,
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-text {
    color: #c0c4d1 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__heading .k-menu__link-icon,
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-icon {
    color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
    background-color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
    background-color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__heading .k-menu__ver-arrow,
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__ver-arrow {
    color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__arrow {
    color: #242939; }
  .k-aside-menu .k-menu__nav > .k-menu__section .k-menu__section-text {
    color: #606a8c ; }
  .k-aside-menu .k-menu__nav > .k-menu__section .k-menu__section-icon {
    color: #606a8c ; }
  .k-aside-menu .k-menu__nav > .k-menu__separator {
    border-bottom: 1px solid #606a8c ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__heading .k-menu__link-text,
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__link .k-menu__link-text {
    color: #989eb3 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__heading .k-menu__link-icon,
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__link .k-menu__link-icon {
    color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
    background-color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
    background-color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__heading .k-menu__ver-arrow,
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item > .k-menu__link .k-menu__ver-arrow {
    color: #687393 ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading,
    .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link {
      background-color: #1f2433 ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-text,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-text {
        color: #ffffff ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-icon,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-icon {
        color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
        background-color: #3c75fd ; }
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__ver-arrow,
      .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__ver-arrow {
        color: #3c75fd ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__section .k-menu__section-text {
    color: #606a8c ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__section .k-menu__section-icon {
    color: #606a8c ; }
  .k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__separator {
    border-bottom: 1px solid #606a8c ; }

@media (min-width: 1025px) {
  .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item {
    background: transparent; }
    .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link > .k-menu__link-icon {
      color: #7c87a6; }
    .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--hover, .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open {
      background: transparent !important; }
      .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--hover > .k-menu__link > .k-menu__link-icon, .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link > .k-menu__link-icon {
        color: #3c75fd ; }
    .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here, .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active {
      background: transparent !important; }
      .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link > .k-menu__link-icon, .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link > .k-menu__link-icon {
        color: #3c75fd ; }
    .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item:hover {
      background: transparent; }
      .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item:hover > .k-menu__link > .k-menu__link-icon {
        color: #3c75fd !important; } }

@media (min-width: 1025px) {
  .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav,
  .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15); }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-text,
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-text {
      color: #696e92 ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-icon,
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-icon {
      color: #a8aabb ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
      background-color: #a8aabb ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
      background-color: #a8aabb ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__ver-arrow,
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__heading .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item > .k-menu__link .k-menu__ver-arrow {
      color: #a8aabb ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open {
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading,
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link {
        background-color: #f8f8fb ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-text,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-text,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-text,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-text {
          color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-icon,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-icon,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-icon,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-icon {
          color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__ver-arrow,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__ver-arrow,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__heading .k-menu__ver-arrow,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--open > .k-menu__link .k-menu__ver-arrow {
          color: #3c75fd ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here {
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__heading,
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__link,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__heading,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__link {
        background-color: #f8f8fb ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
          background-color: #3c75fd ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active {
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading,
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link {
        background-color: #f8f8fb ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-text,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-text,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text {
          color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-icon,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-icon,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-icon,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-icon {
          color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__ver-arrow,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__ver-arrow,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__heading .k-menu__ver-arrow,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__ver-arrow {
          color: #3c75fd ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover {
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading,
      .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading,
      .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link {
        background-color: #f8f8fb ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-text,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-text,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-text,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-text {
          color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-icon,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-icon,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-icon,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-icon {
          color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--dot > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--dot > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__link-bullet.k-menu__link-bullet--line > span,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__link-bullet.k-menu__link-bullet--line > span {
          background-color: #3c75fd ; }
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__ver-arrow,
        .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__ver-arrow,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__heading .k-menu__ver-arrow,
        .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(.k-menu__item--here):not(.k-menu__item--active):hover > .k-menu__link .k-menu__ver-arrow {
          color: #3c75fd ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__section .k-menu__section-text,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__section .k-menu__section-text {
      color: #a4a7bd ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__section .k-menu__section-icon,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__section .k-menu__section-icon {
      color: #aaacc2 ; }
    .--minimize .k-aside-menu .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__separator,
    .k-aside-menu.k-aside-menu--dropdown .k-menu__nav .k-menu__item:not([k-menu-submenu-mode=accordion]).k-menu__item--hover > .k-menu__submenu .k-menu__subnav .k-menu__separator {
      border-bottom: 1px solid rgba(0, 0, 0, 0.07) ; } }

.k-aside .k-aside-menu > .k-menu__nav .k-menu__item > .k-menu__link [class^="flaticon2-"],
.k-aside .k-aside-menu > .k-menu__nav .k-menu__item > .k-menu__link [class*=" flaticon2-"] {
  color: #434d6b; }
